body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.app {
  height: 100vh;
  max-height: 100vh;
}
